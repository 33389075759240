import {FETCH_POSTS, FETCH_USERS,CART_ITEMS,ACCOUNT_ITEM} from './types'

const initState = {
    savedposts: [],
    savedusers: [],
    savedCartItems:[],
    savedAcountItem:''

  }
  
  const postReducer = (state = initState, action) => {
    console.log(action.type)
    switch (action.type) {
        case FETCH_USERS:
        return {
          ...state,
          savedusers: action.savedusers
          
        };
      case FETCH_POSTS:
        return {
          ...state,
          savedposts: action.savedposts
          
        };
        case CART_ITEMS:
            return {
              ...state,
              savedCartItems: action.savedCartItems
              
            };
            case ACCOUNT_ITEM:
            return {
              ...state,
              savedAcountItem: action.savedAcountItem
              
            };
        
      default:
        
        return state;
    }
  };
  
  export default postReducer