import React, { Component } from 'react'
import style from './Contact.module.scss';
import { NavLink } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var image = 'https://images.pexels.com/photos/4155091/pexels-photo-4155091.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
let imageFamliy = "https://images.pexels.com/photos/4260096/pexels-photo-4260096.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let imageDopeness = "https://images.pexels.com/photos/209728/pexels-photo-209728.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let imageRules = "https://images.pexels.com/photos/4027658/pexels-photo-4027658.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let imageCharacter = "https://images.pexels.com/photos/4116569/pexels-photo-4116569.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let equality = "https://images.pexels.com/photos/6185621/pexels-photo-6185621.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"


class Contact extends Component {
    state = {
        value: 'Others',
        duration: 'Any',
        name: '',
        email: '',
        message: ''
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    handleExchangeChange(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ value: event.target.value });
    }
    handleDurationChange(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ duration: event.target.value });
    }
    inputChange(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ [event.target.id]: event.target.value });
    }
    sendMessage = async () => {

        console.log('name', this.state.name)
        console.log('email', this.state.email)
        console.log('message', this.state.message)
        console.log('duration', this.state.duration)
        console.log('category', this.state.value)
        if (this.state.name === '' && this.state.email === '' && this.state.message === '' && this.state.value === '' && this.state.duration === '') {
            this.showToast('Message send successfully')
            this.setState({
                value: 'Others',
                duration: 'Any',
                name: '',
                email: '',
                message: ''
            })
        } else {
            this.showToast('All fields must be filled')
        }
    }
    showToast = (text) => { toast.info(text) }
    render() {
        return (
            <div className={style.mainContainer}>
                <div className={style.contactDiv}>
                    <h1 >Contact Us</h1>
                    <p>Your Name (required)</p>
                    <input className={style.contactsInput} type='text' id='name' onChange={(event) => this.inputChange(event)}></input>


                    <div className={style.catDiv}>
                        <div>
                            <p>Category (required)</p>

                            <select className={style.contactsInput2} value={this.state.value} onChange={(event) => this.handleExchangeChange(event)}>
                                <option selected value="Any">Any</option>
                                <option value="Birdwatching Safaris">Birdwatching Safaris</option>
                                <option value="Cultural Safaris">Cultural Safaris</option>
                                <option value="Family Friendly Safaris">Family Friendly Safaris</option>
                                <option value="Group Safaris">Group Safaris</option>
                                <option value="Luxury Safaris">Luxury Safaris</option>
                                <option value="Multi-Destination Safaris">Multi-Destination Safaris</option>
                                <option value="Multi Activity Safaris">Multi Activity Safaris</option>
                                <option value="Off the Beaten Track Safaris">Off the Beaten Track Safaris</option>
                                <option value="Solo Travellers">Solo Travellers</option>
                                <option value="Special Offers">Special Offers</option>
                                <option value="Wild Camping">Wild Camping</option>
                                <option value="Wildlife Safaris">Wildlife Safaris</option>
                            </select>
                        </div>
                        <div>
                            <p>Duration (required)</p>

                            <select className={style.contactsInput2} value={this.state.duration} onChange={(event) => this.handleDurationChange(event)}>
                                <option selected value="Any">Any</option>
                                <option value="1 Day Tour">1 Day Tour</option>
                                <option value="2-4 Days Tour">2-4 Days Tour</option>
                                <option value="5-7 Days Tour">5-7 Days Tour</option>
                                <option value="7+ Days Tour">7+ Days Tour</option>
                            </select>
                        </div>
                    </div>



                    <p>Your Email (required)</p>
                    <input className={style.contactsInput} type='text' id='email' onChange={(event) => this.inputChange(event)}></input>

                    <p>Your Message</p>
                    <textarea className={style.messageInput} type='text' id='message' onChange={(event) => this.inputChange(event)}></textarea>
                    <div className={style.footerSendDiv} onClick={() => this.sendMessage()}>
                        <p style={{ fontSize: 20, color: '#fff', fontWeight: '700', margin: 0 }}>Send</p>
                    </div>

                </div>


                <ToastContainer />
            </div>
        )
    }
}
export default Contact














