import React, { Component } from 'react'
import style from './HotDeals.module.scss';
import { MdKeyboardArrowLeft,MdOutlineClose,MdEmail} from "react-icons/md";
import {FaHotjar,FaPhoneAlt} from "react-icons/fa";
import {IoLogoWhatsapp} from 'react-icons/io';
import firebase from 'firebase'

class HotDeals extends Component {
    state={
        showModal:false,
        time:0,
        theImg:'',
        theImageModal:false,
        theAdsArray:[]
           
            
            
        
    }
    
    fetchAdsInfo=async()=>{
        try {
          const theRef = firebase.database().ref('/hotDeals/');
          var adsArray=[]
          var adsArray2=[]
         await theRef.once('value', (snapshot) => {
           snapshot.forEach((data) => {
            const id = data.key
            const title = data.val().title
            const details= data.val().details
            const image = data.val().image
            adsArray={
              title: title, details:details, image:image, id:id
            }
             
            adsArray2.push(adsArray)
            
           })
          })
          this.setState({
            theAdsArray:this.reverseArr(adsArray2) 
           })
          console.log('adsArray', this.state.adsArray)
        } catch (error) {
          
        }
      }
      reverseArr=(array)=>{
        return array.map((item,idx) => array[array.length-1-idx])
      }
      componentDidMount(){
       //this.updateMe()
       this.fetchAdsInfo()
      }
      componentWillUnmount(){
        clearInterval(this.setInterval);
      }
      showModal=(img)=>{
        //this.setState({showModal:true})
        this.setState({theImg:img,theImageModal:true})
    }
    hideModal=()=>{
        //this.setState({showModal:false})
        this.setState({theImg:'',theImageModal:false})
    }
    doNothing=(event)=>{
       event.preventDefault()
       event.stopPropagation()
    }
    render() {
        const detailsList=this.state.theAdsArray.map(item=>{
            return(
                <div className={style.imghoverzoom} key={item.id} onClick={()=>this.showModal(item.image)}>
                 <img style={{}} src={item.image}/>
                <h1>{item.title}</h1>
                <h4>{item.details}</h4>
                {/*<p>{price}</p>
                <h5>{sharing}</h5>*/}
                </div>
            )
        })
        return (
            <div className={style.bodySection4}>
                {/*<div className={style.donorsVolCont}>
                    <div className={style.menuCont}>
                    <h1>Top International Getaways</h1>
                   
                    </div>
                    <p>Popular International destinations for a vacation</p>
                </div>*/}
                <div className={style.donorItemsCont} id='theDiv'>
                 {detailsList}
                 
                </div>
               {/* <div className={style.arrovDiv}>
                 <MdKeyboardArrowLeft className={style.arrovDivLeft} onClick={()=>this.theScroll(-305)}/>
                 <MdKeyboardArrowRight className={style.arrovDivRight} onClick={()=>this.theScroll(305)}/>
                 </div>*/}
                 {this.state.showModal?<div className={style.modal} onClick={()=>this.hideModal()}>
                <div className={style.modalCont} onClick={(event)=>this.doNothing(event)}>
                <h5>Book/Enquire</h5>
                <p className={style.book}>For bookings and enquiries please reach us through the below contacts</p>
                <div className={style.contDiv}><FaPhoneAlt className='ulicon' size={14} color='#000'/><p>+254 712 342 209</p></div>
                <div className={style.contDiv}><MdEmail className='ulicon' size={16} color='#000'/><p>info@tawandatoursandtravels.com</p></div>
                <div className={style.contDiv}><IoLogoWhatsapp className='ulicon' size={16} color='#000'/><p>+254 712 342 209</p></div>
                <p className={style.close} onClick={()=>this.hideModal()}>Close</p>
                </div>
                </div>:null}
                {this.state.theImageModal?<div className={style.bigImageCont} onClick={()=>this.hideModal()}>
                <MdOutlineClose className={style.closeIc} onClick={()=>this.hideModal()}/>
                <img style={{}} src={this.state.theImg} onClick={(event)=>this.doNothing(event)}/>
                </div>:null}
            </div>
        )
    }
}

export default HotDeals
