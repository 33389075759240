import React, { Component } from 'react'
import style from './Leisure.module.scss';
import Safaris from './Safaris'
import {NavLink} from 'react-router-dom'

class Leisure extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div className={style.mainContainer}>
                <div className={style.backImg}>
                <h3>Safari Packages</h3>
                <div className={style.lineDiv}></div>
                </div>
                <div className={style.contBottom}>
                
                {/*<p className={style.servP1}>Safari Packages</p>*/}
                
                <Safaris/>
                </div>
                <div className={style.contBottom2}>
                <h3>TOP PROMOTIONS</h3>
                <h4>BEST TRAVEL PACKAGES AVAILABLE</h4>
                <div className={style.backImg2}>
                    <div className={style.reqDiv}>
                   
                <p id={style.reqP} >REQUEST A TOUR</p>
                <input className={style.contInput} placeholder='Name' type='text' id='lastName'></input>
                <input className={style.contInput} placeholder='E-mail' type='text' id='lastName'></input>
                <textarea className={style.messageInput} placeholder='Message' type='text'></textarea>
                <div className={style.sendDiv}>
                       <p   style={{fontSize:20, color:'#fff', fontWeight:'700', margin:0}}>Send</p>
                       </div>
                
                
                    </div>
                </div>
                </div>
            </div>
        )
    }
}
export default Leisure














