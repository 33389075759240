import React, { Component } from 'react'
import style from './GetawaysInt.module.scss';
import { MdKeyboardArrowLeft,MdKeyboardArrowRight,MdEmail} from "react-icons/md";
import {FaHotjar,FaPhoneAlt} from "react-icons/fa";
import {IoLogoWhatsapp} from 'react-icons/io';

class GetawaysInt extends Component {
    state={
        showModal:false,
        time:0,
         details:[
            {id:1,name:'Dubai', det:'Experience the magic ', country:'Nairobi, Kenya', price:20000, image:'https://images.pexels.com/photos/2044434/pexels-photo-2044434.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
            {id:2,name:'Zanzibar',det:'For that relaxing vacation', country:'Mombasa, Kenya', price:35000, image:'https://images.pexels.com/photos/4860993/pexels-photo-4860993.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
            {id:3,name:'Thailand',det:'Awesome food experienece', country:'Mombasa, Kenya', price:38000, image:'https://images.pexels.com/photos/4669225/pexels-photo-4669225.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
            {id:4,name:'Mauritius',det:'Experience the elegance', country:'Mombasa, Kenya', price:63000, image:'https://images.pexels.com/photos/4874100/pexels-photo-4874100.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
            {id:5,name:'Seychelles',det:'The bauty is breath taking', country:'Mombasa, Kenya', price:32000, image:'https://images.pexels.com/photos/404326/pexels-photo-404326.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
            
        ]
    }
    
      componentDidMount(){
       //this.updateMe()
      }
      componentWillUnmount(){
        clearInterval(this.setInterval);
      }
      showModal=()=>{
        this.setState({showModal:true})
    }
    hideModal=()=>{
        this.setState({showModal:false})
    }
    doNothing=(event)=>{
       event.preventDefault()
       event.stopPropagation()
    }
    render() {
        const detailsList=this.state.details.map(item=>{
            let price=item.price.toLocaleString()
            price='Starting from '+price
            let sharing = '*Per person sharing'
            return(
                <div className={style.imghoverzoom} key={item.id} onClick={()=>this.showModal()}>
                 <img style={{}} src={item.image}/>
                <h1>{item.name}</h1>
                <h4>{item.det}</h4>
                {/*<p>{price}</p>
                <h5>{sharing}</h5>*/}
                </div>
            )
        })
        return (
            <div className={style.bodySection4}>
                {/*<div className={style.donorsVolCont}>
                    <div className={style.menuCont}>
                    <h1>Top International Getaways</h1>
                   
                    </div>
                    <p>Popular International destinations for a vacation</p>
                </div>*/}
                <div className={style.donorItemsCont} id='theDiv'>
                 {detailsList}
                 
                </div>
               {/* <div className={style.arrovDiv}>
                 <MdKeyboardArrowLeft className={style.arrovDivLeft} onClick={()=>this.theScroll(-305)}/>
                 <MdKeyboardArrowRight className={style.arrovDivRight} onClick={()=>this.theScroll(305)}/>
                 </div>*/}
                 {this.state.showModal?<div className={style.modal} onClick={()=>this.hideModal()}>
                <div className={style.modalCont} onClick={(event)=>this.doNothing(event)}>
                <h5>Book/Enquire</h5>
                <p className={style.book}>For bookings and enquiries please reach us through the below contacts</p>
                <div className={style.contDiv}><FaPhoneAlt className='ulicon' size={14} color='#000'/><p>+254 712 342 209</p></div>
                <div className={style.contDiv}><MdEmail className='ulicon' size={16} color='#000'/><p>info@tawandatoursandtravels.com</p></div>
                <div className={style.contDiv}><IoLogoWhatsapp className='ulicon' size={16} color='#000'/><p>+254 712 342 209</p></div>
                <p className={style.close} onClick={()=>this.hideModal()}>Close</p>
                </div>
                </div>:null}
            </div>
        )
    }
}

export default GetawaysInt
