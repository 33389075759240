import React, { Component } from 'react'
import './Footer.scss';
import {IoLogoWhatsapp} from 'react-icons/io';
import { MdEmail } from "react-icons/md";
import {NavLink} from 'react-router-dom';
import { FaSearch,FaHome,FaCarAlt,FaFacebook,FaInstagram,FaTwitterSquare,FaPhoneAlt,FaBars} from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Footer extends Component {
    showToast = (text) =>{toast.info("Coming soon")}
    render() {
        return (
            <div style={{backgroundColor:'#174905', paddingBottom:50}}>
            <div className='footerDiv'>
                <div className='footerDivs'>
                 <h1 className='divCentreHead1'>Tawanda Tours &amp; Travels</h1>
                 <ul className='ulFooter2'>
                     <p style={{fontSize:'14px',lineHeight:'20px',color:'#fff'}}>We are an home grown company with a vast experience in service industry.</p>
                     <li className='ulLinkF'><FaPhoneAlt className='ulicon' size={14} color='#fff'/><p>+254 712 342 209</p></li>
                     <li className='ulLinkF'><MdEmail className='ulicon' size={16} color='#fff'/><p>info@tawandatravels.com</p></li>
                     <li className='ulLinkF'><IoLogoWhatsapp className='ulicon' size={16} color='#fff'/><p>+254 712 342 209</p></li>
                  
                        {/*<li><NavLink to='/' className='FooterLi'>About Us</NavLink></li>
                        <li><NavLink to='/aboutUs' className='FooterLi'>Why Us</NavLink></li>
                        <li><NavLink to='/contacts' className='FooterLi'>Terms and Conditions</NavLink></li>
                        <li><NavLink to='/terms' className='FooterLi'>FAQs</NavLink></li>
                        <li><NavLink to='/privacy' className='FooterLi'>Photo Gallery</NavLink></li>*/}
                    </ul>
                    <p style={{color:'#fff',marginTop:'5px'}}>Social Media</p>
                    <div  className='lineDivIc'>
            <a target="_blank" href="https://www.facebook.com/Tawanda-Tours-And-Travels-111662317951843"><FaFacebook className='socialIcons'/></a>
            <a target="_blank" href='https://www.instagram.com/tawanda_tours1/'><FaInstagram className='socialIcons'/></a>
            <a target="_blank" href='https://twitter.com/Tawanda_tours'><FaTwitterSquare className='socialIcons'/></a>
           
            </div>
                </div>
                <div className='footerDivs'>
                <h1 className='divCentreHead2'>Quick Links</h1>
                    <ul className='ulFooter'>
                        <li><NavLink to='/' className='FooterLi'>Home</NavLink></li>
                        <li><NavLink to='/corporate' className='FooterLi'>Corporate</NavLink></li>
                        <li><NavLink to='/leisure' className='FooterLi'>Leisure</NavLink></li>
                        <li><NavLink to='/about' className='FooterLi'>About</NavLink></li>
                    </ul>
                      
                 </div>
                <div className='footerDivs'>
                <h1 className='contactUs' >Contact Us</h1>
                <input className='contactsInput' placeholder='Name' type='text' id='theName'></input>
                <input className='contactsInput' placeholder='Email' type='text' id='theEmail'></input>
                <input className='contactsInput' placeholder='Message' type='text' id='theMessage'></input>
                <div className='footerSendDiv'>
                       <p   style={{fontSize:20, color:'#fff', fontWeight:'700', margin:0}}>Send</p>
                       </div>
                 </div>
                 
                
            </div>
            <div className='lineDiv'>
            <p className='footerP'>Copyright 2021 Tawanda Tours &amp; Travels</p>
           
           
            </div>
            <ToastContainer/>
            </div>
        )
    }
}

export default Footer
