import React, { Component } from 'react'
import style from './Corporate.module.scss';
import {NavLink} from 'react-router-dom'
import { FaRegCreditCard,FaPlaneDeparture,FaHotel,FaCarAlt,FaRegAddressCard,FaBusinessTime} from "react-icons/fa";
import {BsPeople} from "react-icons/bs";
var image='https://images.pexels.com/photos/4155091/pexels-photo-4155091.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
let imageFamliy ="https://images.pexels.com/photos/4260096/pexels-photo-4260096.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let imageDopeness ="https://images.pexels.com/photos/209728/pexels-photo-209728.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let imageRules ="https://images.pexels.com/photos/4027658/pexels-photo-4027658.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let imageCharacter ="https://images.pexels.com/photos/4116569/pexels-photo-4116569.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let equality ="https://images.pexels.com/photos/6185621/pexels-photo-6185621.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"


class Corporate extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div className={style.mainContainer}>
                <h3>BUSINESS TRAVEL</h3>
                <h4>Trusted Concierge</h4>
                <div className={style.contBottom3}>
                    <div>
                    <FaPlaneDeparture  className={style.servIcon}/>
                     <p>FLIGHT RESERVATIONS</p>
                    </div>
                    <div>
                    <FaHotel  className={style.servIcon}/>
                     <p>HOTEL RESERVATIONS</p>
                    </div>
                    <div>
                    <FaCarAlt  className={style.servIcon}/>
                    <p>AIRPORT TRANSFERS</p>
                    </div>
                    <div>
                    <FaRegCreditCard  className={style.servIcon}/>
                     <p>VISA SERVICES</p>
                    </div>
                    </div>
                    <div className={style.contBottom3}>
                    <div>
                    <FaBusinessTime  className={style.servIcon}/>
                     <p>TRAVEL INSURANCE</p>
                    </div>
                    <div>
                    <BsPeople  className={style.servIcon}/>
                     <p>MEETINGS &amp; EVENTS</p>
                    </div>
                    <div>
                    <FaRegAddressCard  className={style.servIcon}/>
                    <p>LEISURE TRAVEL</p>
                    </div>
                    <div>
                    <BsPeople  className={style.servIcon}/>
                     <p>GROUP BOOKINGS</p>
                    </div>
                    </div>
            </div>
        )
    }
}
export default Corporate














