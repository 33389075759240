import React, { Component } from 'react'
import style from './Reel.module.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FaAngleLeft,FaAngleRight } from 'react-icons/fa';
import firebase from 'firebase'

const theReel=[
    {id:1, title:'Honey Moon Packages', color:'red', details:'Giving you a Honey Moon experience you will never forget', image:'./c4.jpg'},
    {id:2, title:'Beach Holiday Packages',  color:'black', details:'Giving an experience of the finest beaches around the Globe', image:'./c2.jpg'},
    {id:3, title:'Family Packages',  color:'black', details:'Budget family getaways with the lowest prices', image:'./c3.jpg'},
    {id:3, title:'Kenya Safaris',  color:'black', details:'Tour around Kenyas serene enviroment, game parks and hotels', image:'./c1.jpg'}]
class Reel extends Component {
  state={
    theAdsArray:[]
  }
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
      }
      componentDidMount(){
        this.fetchAdsInfo()
        console.log('niko hapa reel')
      }

      fetchAdsInfo=async()=>{
       
        try {
          const theRef = firebase.database().ref('/reel/');
          var adsArray=[]
          var adsArray2=[]
         await theRef.once('value', (snapshot) => {
           snapshot.forEach((data) => {
            const id = data.key
            const title = data.val().title
            const details= data.val().details
            const image = data.val().image
            const color = data.val().color
            adsArray={
              title: title, details:details, image:image, id:id,color:color
            }
             
            adsArray2.push(adsArray)
            
           })
          })
          this.setState({
            theAdsArray:adsArray2
           })
          console.log('adsArray', this.state.adsArray)
        } catch (error) {
          
        }
      }

      next() {
        this.slider.slickNext();
      }
      previous() {
        this.slider.slickPrev();
      }
      scrollToPos=()=>{
      window.scrollTo(0, 550)
      }
    
    render() {
         let donateStyle=''
         let reelTextStyle=''
         let titleStyle=''
        const settings = { 
            /*infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            className:"slides",
            fade: true,
            autoplay: true,
            pauseOnHover: false,
      speed: 700,
      autoplaySpeed: 5000,
      cssEase: "linear"*/
      infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          className:"slides",
          autoplay: true,
          pauseOnHover: false,
          speed: 400,
          autoplaySpeed: 5000,
          cssEase: "linear",
      
          };
        return (
            <div className={style.boduTitleMainCont}>
                <Slider ref={c => (this.slider = c)} {...settings}>
                  
                {theReel.map((item)=>{
                  if(item.color!='black'){
                    donateStyle={
                      backgroundColor:'#174905'
                    }
                    reelTextStyle={
                      color:'#fff'
                    }
                    titleStyle={
                      color:'#fff'
                    }
                  }else{
                    donateStyle={
                     backgroundColor:'#174905'
                    }
                    reelTextStyle={
                      color:'#fff'
                    }
                    titleStyle={
                      color:'#fff'
                    }
                  }
                 return(
                       <div  className={style.titleDivCont} key={item.id}>
                       <div className={style.titleCont}>
                       <div className={style.titleCont2}>
                       {item.title?<h4 className={style.titleH4} id={style.titleH4} style={titleStyle}>{item.title}</h4>:null}
                       {item.details?<p className={style.detailsP} style={reelTextStyle}>{item.details} </p>:null}
                       <p className={style.seeP}>SEE MORE</p>
                       </div> </div>
                      <div className={style.aroowsDiv}>
                      <FaAngleLeft className={style.backFang}  onClick={()=>this.previous()}/>
                      <FaAngleRight className={style.frontFang}  onClick={()=>this.next()}/>
                      </div>
                     <img className={style.theImage} src={item.image}/>
                     
                    
                     </div>

                 )})}

                </Slider>
                
            </div>
        )
    }
}
export default Reel
