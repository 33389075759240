import React, { Component } from 'react'
import style from './Safaris.module.scss';
import {FaHotjar,FaPhoneAlt} from "react-icons/fa";
import {IoLogoWhatsapp} from 'react-icons/io';
import { MdEmail } from "react-icons/md";
class Safaris extends Component {
    state={
         showModal:false,
         details:[
            {id:1,name:'The Great Wilderbeast Migration',thePlace:'Maasai Mara',activity:'Bush Safari', det:'The Wildebeest Migration, is one of the “Seven New Wonders of the World” and also known as The World Cup of Wildlife. This is a safari you should go on!!', country:'Nairobi, Kenya', price:20000, image:'https://images.pexels.com/photos/5989882/pexels-photo-5989882.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
            {id:2,name:'Nairobi Day Tour',thePlace:'Nairobi City',activity:'Hiking',det:'Experience what Nairobi City “The World’s only Wildlife Capital” has to offer. A short drive out of Nairobi’s central business district is the Nairobi National Park.', country:'Mombasa, Kenya', price:35000, image:'https://images.pexels.com/photos/5064662/pexels-photo-5064662.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
            {id:3,name:'Beach Safari',thePlace:'Coastal Kenya',activity:'Beach Safari',det:'Explore the beautiful stellar beaches. Wild waters of the Indian Ocean you can play in. A revitalizing sun for that beautiful tan. Exciting coast culture.', country:'Mombasa, Kenya', price:38000, image:'https://images.pexels.com/photos/3601422/pexels-photo-3601422.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
            {id:4,name:'Hells Gate Experience',thePlace:'Naivasha Kenya',activity:'The Rift Tour',det:'Enjoy Kenya Great Rift Valley outstanding views along the way to Naivasha and experince the Rift serene on your stay at Naivasha', country:'Mombasa, Kenya', price:63000, image:'https://images.pexels.com/photos/162206/lioness-animal-predator-cat-162206.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
            /*{id:5,name:'Swahili Beach',det:'3 days per person sharing', country:'Mombasa, Kenya', price:32000, image:'https://images.pexels.com/photos/404326/pexels-photo-404326.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
            {id:6,name:'Diani Sea Lodge',det:'3 days per person sharing', country:'Mombasa, Kenya', price:52000, image:'https://images.pexels.com/photos/1030948/pexels-photo-1030948.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},*/
        ]
    }
    showModal=()=>{
        this.setState({showModal:true})
    }
    hideModal=()=>{
        this.setState({showModal:false})
    }
    doNothing=(event)=>{
       event.preventDefault()
       event.stopPropagation()
    }
    render() {
        const detailsList=this.state.details.map(item=>{
            let price=item.price.toLocaleString()
            price='Starting from '+price
            let sharing = '*Per person sharing'
            return(
                <div className={style.imghoverzoom} key={item.id} onClick={()=>this.showModal()}>
                 <img style={{}} src={item.image}/>
                 <div className={style.det1}>
                 <p>{item.name}</p>
                 </div>
                 <div className={style.actDiv}>
                 <p className={style.actP1}>{item.thePlace}</p>
                 <p className={style.actP2}>{item.activity}</p>
                 </div>
                 <p className={style.det3}>{item.det}</p>
                 <p className={style.det5}>Enquire</p>
                </div>
            )
        })
        return (
            <div className={style.bodySection4} >
                <div className={style.donorItemsCont}>
                 {detailsList}
                </div>
                {this.state.showModal?<div className={style.modal} onClick={()=>this.hideModal()}>
                <div className={style.modalCont} onClick={(event)=>this.doNothing(event)}>
                <h5>Book/Enquire</h5>
                <p className={style.book}>For bookings and enquiries please reach us through the below contacts</p>
                <div className={style.contDiv}><FaPhoneAlt className='ulicon' size={14} color='#000'/><p>+254 712 342 209</p></div>
                <div className={style.contDiv}><MdEmail className='ulicon' size={16} color='#000'/><p>info@tawandatoursandtravels.com</p></div>
                <div className={style.contDiv}><IoLogoWhatsapp className='ulicon' size={16} color='#000'/><p>+254 712 342 209</p></div>
                <p className={style.close} onClick={()=>this.hideModal()}>Close</p>
                </div>
                </div>:null}
            </div>
        )
    }
}

export default Safaris
