import React, { Component } from 'react';
import  './NavBar.scss';
import {NavLink,withRouter} from 'react-router-dom';
import { MdFlight,MdCardTravel,MdFlightTakeoff,MdClose} from "react-icons/md";
import { FaSearch,FaHome,FaCarAlt,FaFacebook,FaInstagram,FaTwitterSquare,FaYoutubeSquare,FaBars} from "react-icons/fa";
import { saveItemAccount } from '../../redux/postActions';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

 class NavBar extends Component {
    constructor(props) {
        super(props)
        this.state={
            queryName:'',
            openAccDiv:false
        }
      }
    onScroll = () => { 
        const isTop=window.scrollY>100;
        const nav=document.getElementById('shopNav');
        if(isTop){
            nav.classList.add("shopScrolled");
        }else{
            nav.classList.remove("shopScrolled");
        }
    }
     componentDidMount(){
        window.addEventListener("scroll", this.onScroll, false);
         
    }
    componentWillUnmount(){
        window.removeEventListener("scroll", this.onScroll, false);
       // window.removeAllListeners("scroll");
    }
    showToast = (text) =>{toast.info(text)}
    showToast2 = (text) =>{
        toast.info(text)
        this.setState({openAccDiv:false})
    }
   
    searchChange=(event)=>{
      this.setState({
        queryName:event.target.value
      })
       }
       openTheAcDiv=async()=>{
        await this.setState({
            openAccDiv:true
        })
       }
       closeTheAcDiv=async()=>{
        await this.setState({
            openAccDiv:false
        })
       }
       stopShift=(event)=>{
        //event.stopPropagation();
       // event.preventDefault()
       }
    render() {
        return (
            <div className='shopNav' id='shopNav'>
                 <div className='shopNav0'>
                 <div className='shopNav2'>
                
                 <NavLink to='/' className='NavLogo' style={{display:'flex',alignItems:'center',textDecoration:'none'}}>
                     <img src='./logo.png'></img>
                     <h2 id='coName'>Tawanda Tours</h2></NavLink>
                 <div className='shopNav3'>
                 <NavLink to='/' className='ulLink'><h3>HOME</h3></NavLink>
                 <NavLink to='/corporate' className='ulLink'><h3>CORPORATE</h3></NavLink>
                 <NavLink to='/leisure' className='ulLink'><h3 >LEISURE</h3></NavLink>
                 <NavLink to='/services' className='ulLink'><h3 >SERVICES</h3></NavLink>
                 <NavLink to='/about' className='ulLink'><h3 >ABOUT</h3></NavLink>
                 <NavLink to='/contact' className='ulLink'><h3 >CONTACT</h3></NavLink>
                </div>
                </div>
                {this.state.openAccDiv?<MdClose className='optionsIcon' size={30} onClick={()=>this.closeTheAcDiv()}/>:
                <FaBars className='optionsIcon' size={20} onClick={()=>this.openTheAcDiv()}/>}
                </div>
                {this.state.openAccDiv?<div className='optionsDiv'>
                 <NavLink to='/' className='ulLink5' onClick={()=>this.setState({openAccDiv:false})}><h3>HOME</h3></NavLink>
                 <NavLink to='/corporate' className='ulLink5' onClick={()=>this.setState({openAccDiv:false})}><h3>CORPORATE</h3></NavLink>
                 <NavLink to='/leisure' className='ulLink5' onClick={()=>this.setState({openAccDiv:false})}><h3>LEISURE</h3></NavLink>
                 <NavLink to='/services' className='ulLink5' onClick={()=>this.setState({openAccDiv:false})}><h3>SERVICES</h3></NavLink>
                 <NavLink to='/about' className='ulLink5' onClick={()=>this.setState({openAccDiv:false})}><h3>ABOUT</h3></NavLink>
                 <NavLink to='/contact' className='ulLink5' onClick={()=>this.setState({openAccDiv:false})}><h3>CONTACT</h3></NavLink>
                </div>:null}
               { /*
                <NavLink to='/' className='NavLogoIcon'><FaBars className='optionsIcon'/></NavLink>
               <div className='shopNav5'>
                 <NavLink to='/' className='ulLink'><FaHome className='ulicon'/><h3>Home</h3></NavLink>
                 <NavLink to='/' className='ulLink'><MdCardTravel className='ulicon'/><h3>Packages</h3></NavLink>
                 <NavLink to='/' className='ulLink'><MdFlightTakeoff className='ulicon'/><h3>Flights</h3></NavLink>
                 <NavLink to='/' className='ulLink'><FaCarAlt className='ulicon'/><h3>Car Hire</h3></NavLink>
                </div>*/}
                <ToastContainer/>
            </div>
        )
    }
}
export default NavBar

//export default withRouter (ShopNav)
