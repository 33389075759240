import React, { Component } from 'react'
import style from './AboutUs.module.scss';
import {NavLink} from 'react-router-dom'
var image='https://images.pexels.com/photos/4155091/pexels-photo-4155091.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
let imageFamliy ="https://images.pexels.com/photos/4260096/pexels-photo-4260096.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let imageDopeness ="https://images.pexels.com/photos/209728/pexels-photo-209728.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let imageRules ="https://images.pexels.com/photos/4027658/pexels-photo-4027658.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let imageCharacter ="https://images.pexels.com/photos/4116569/pexels-photo-4116569.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let equality ="https://images.pexels.com/photos/6185621/pexels-photo-6185621.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let zablon='https://images.pexels.com/photos/6949880/pexels-photo-6949880.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
let sharon='https://images.pexels.com/photos/7468092/pexels-photo-7468092.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'

class AboutUs extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <>
             <div className={style.backImg}>
                <h3>Who We Are</h3>
                <div className={style.lineDiv}></div>
                </div>
            <div className={style.mainContainer}>
                {/*<h3>Who We Are</h3>*/}
                <h2>Overview</h2>
                <div className={style.div1}>
                <p>Tawanda Tours and Travel is an home grown company with a vast experience in service industry. Our strength comes from a strong background training and qualification in travels and tours industry that spans to almost a decade. The future of our travel industry will depend on our ability to  seamless upholds the 3 Ps (Planet, People, and Profit).</p>
                <h2>Our Mission</h2>
                <p>Serving our customer, searching their entire satisfaction and providing touristic services of quality, committing to the environmental, social and cultural aspect of our country.</p>
                <h2>Our Vision</h2>
                <p> We seek to differentiate ourselves as an entity that is environmental centric, human centric quality service provision, and profit centric both locally and globally. We focus on growth by offering more and a wider range of unique tours and travel around the world, and expand our visibility on the web.</p>
                </div>
                <div className={style.div2}>
                <h2>Our Core Values</h2>
                <ul>
                    <li>A. INTEGRITY:</li>
                    <p>We uphold the highest standards of integrity in all of our actions.</p>
                    <li>B.TEAMWORK:</li>
                    <p>We work together, across boundaries, to meet the needs of our customers and to help our Company win.</p>
                    <li>C. RESPECT FOR PEOPLE:</li>
                    <p>We value our people, encourage their development and reward their performance.</p>
                    <li>D. DILIGENCE:</li>
                    <p>We go the extra mile to provide end to end services hence making travel fun and seamless to our clients.</p>
                    <li>E. TRUST:</li>
                    <p>Like the saying goes Trust is earned not demanded, such a Vital element in life, we practice and give trust.</p>
                </ul>
               {/*<h4>Meet Our Team</h4>
                <div className={style.teamDiv}>
                <div className={style.causedivzoom}>
                <img src={zablon}/>
                <p id={style.P1}>Mr. Zablon Otieno</p>
                <p id={style.P2}>Chief Executive Officer</p>
                <p id={style.P3}>Email: zablon@tawandatours.com</p>
                </div>
                <div className={style.causedivzoom}>
                <img src={sharon}/>
                <p id={style.P1}>Ms. Sharon Otieno</p>
                <p id={style.P2}>Managing Director</p>
                <p id={style.P3}>Email: sharon@tawandatours.com</p>
                </div>
                </div>*/} 
               </div>
            </div>
            </>
        )
    }
}
export default AboutUs














