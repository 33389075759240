import React, { useState } from 'react';
import './App.scss';
import Home from './components/Home/Home';
import AboutUs from './components/Screens/AboutUs';
import Leisure from './components/Screens/Leisure';
import Contact from './components/Screens/Contact';
import Corporate from './components/Screens/Corporate';
import Services from './components/Screens/Services';
import NavBar from './components/NavFooter/NavBar';
import Footer from './components/NavFooter/Footer';
import { BrowserRouter, Route,Switch } from 'react-router-dom'
//git remote add origin https://github.com/BekaTechnologies/tawanda.git

 {/* <BrowserRouter>
    <NavBar/>
       <Switch>
       <Route exact path='/' component={Home}/>
      </Switch>
      <Footer/>
   </BrowserRouter>*/}
function App() {
  return (
     <>
    <BrowserRouter>
    <NavBar/>
       <Switch>
       <Route exact path='/' component={Home}/>
       <Route exact path='/about' component={AboutUs}/>
       <Route exact path='/leisure' component={Leisure}/>
       <Route exact path='/contact' component={Contact}/>
       <Route exact path='/corporate' component={Corporate}/>
       <Route exact path='/services' component={Services}/>
      

      </Switch>
      <Footer/>
   </BrowserRouter>
   
  </>
  );
}

export default App;
