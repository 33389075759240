import React, { Component } from 'react'
import GetawaysInt from './GetawaysInt'
import Products from './Products'
import Deals from './Deals'
import HotDeals from './HotDeals'
import Reel from './Reel'
import style from './Home.module.scss';
import { FaSearch,FaPlaneDeparture,FaHotel,FaCarAlt} from "react-icons/fa";
import {MdMail,MdClose} from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const photos=[
    {id:1,name:'Nairobi Nathional Park', det:'3 days per person sharing', country:'Nairobi, Kenya', price:20000, image:'https://images.pexels.com/photos/4595853/pexels-photo-4595853.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
    {id:2,name:'Continental Resort',det:'3 days per person sharing', country:'Mombasa, Kenya', price:35000, image:'https://images.pexels.com/photos/699967/pexels-photo-699967.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
    {id:3,name:'Flamingo Beach',det:'3 days per person sharing', country:'Mombasa, Kenya', price:38000, image:'https://images.pexels.com/photos/450038/pexels-photo-450038.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
    {id:4,name:'Serena Beach',det:'3 days per person sharing', country:'Mombasa, Kenya', price:63000, image:'https://images.pexels.com/photos/737552/pexels-photo-737552.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
    {id:5,name:'Swahili Beach',det:'3 days per person sharing', country:'Mombasa, Kenya', price:32000, image:'https://images.pexels.com/photos/404326/pexels-photo-404326.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
    {id:6,name:'Diani Sea Lodge',det:'3 days per person sharing', country:'Mombasa, Kenya', price:52000, image:'https://images.pexels.com/photos/1030948/pexels-photo-1030948.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
]
export class Home extends Component {
  state={email:'',name:'',country:'', subscribe:true}
  componentDidMount() {
    window.scrollTo(0, 0)
    const subStatus = localStorage.getItem('subStatus');
    //localStorage.removeItem('subStatus');  
    console.log('subStatus',subStatus)
    if (subStatus!=null) {
      this.setState({subscribe:false})
      //console.log('subStatus22',subStatus)
    }
  }
  inputChange=(event)=>{
    event.preventDefault();
    event.stopPropagation();
    this.setState({ [event.target.id]: event.target.value });
}
 showToast = (text) => { toast.info(text) }
 sendSubscribe=()=>{
   if (this.state.email!=''&&this.state.name!=''&&this.state.country!='') {
    //console.log('the email',this.state.email)
    if (this.state.email.length>8) {
      this.setState({subscribe:false})
      localStorage.setItem("subStatus", true);
      this.showToast('Subscribed successfully')
    }else{this.showToast('Please enter a valid email address')}
   }else{
    this.showToast('All fields must be filled')
   }
      
   }
    render() {
        return (
            <div className={style.container}>
               {/* <h2 className={style.searchTitle}>Search deals on hotels, getaways and much more...</h2>
                <h5 className={style.searchTitle2}>Enter your search query below</h5>
                <div className={style.contSearch}>
                  <FaSearch color='#adadad'/>
                  <p>Enter your search query</p>
                </div>*/}
                <Reel/>
                <div className={style.contBottom2}>
                    <p className={style.servP1}>PREEMINENT SERVICES</p>
                    <p className={style.servP2}>FOR ALL YOUR TRAVEL NEEDS</p>
                    <div className={style.contBottom3}>
                    <div>
                    <FaPlaneDeparture  className={style.servIcon}/>
                     <p>FLIGHT RESERVATIONS</p>
                    </div>
                    <div>
                    <FaHotel  className={style.servIcon}/>
                     <p>HOTEL RESERVATIONS</p>
                    </div>
                    <div>
                    <FaCarAlt  className={style.servIcon}/>
                    <p>AIRPORT TRANSFERS</p>
                    </div>
                    <div>
                    <FaSearch  className={style.servIcon}/>
                     <p>VISA SERVICES</p>
                    </div>
                    </div>
                </div>
                <div className={style.contBottom}>
                <p className={style.servP1}>Hot Deals</p>
                <p className={style.servP2}>Book Now 🙂</p>
                <HotDeals/>
                </div>
                <div className={style.backImg}>
                <h3>International Destinations</h3>
                  <p>Our international tours and travel packages are special, unique, and memorable travel experience for every traveler in a sustainable, honest and transparent way.</p>  
                </div>
                <div className={style.contBottom}>
                <p className={style.servP1}>Top International Getaways</p>
                <p className={style.servP2}>International destinations for a vacation</p>
                <GetawaysInt/>
                </div>
                <div className={style.backImg2}>
                 {/* <h3>Local Destinations</h3>
                  <h4>Great Wilderbeest Migration</h4>
                  <p>Maasai Mara Tour</p>*/}  
                   <h3>Local Destinations</h3>
                  <p>Below are some of the Kenya safari adventure activities we offer including road safaris, flying safari packages, mount Kenya climbing, bird watching, photography safaris among others.</p>  
                </div>
                <div className={style.contBottom}>
                <p className={style.servP1}>Top Local Getaways</p>
                <p className={style.servP2}>PLACES ♥ WE LOVE</p>
                <Products/>
                </div>
                <div className={style.contBottom}>
                <p className={style.servP1}>Deals &amp; Promotions</p>
                <p className={style.servP2}>Book Now 🙂</p>
                <Deals/>
                </div>
                
                {/*<div className={style.contBottom}>
                <VacationTypes/>
                <GetawaysInt/>
                <Getaways/>
               <Products/>
            </div>*/}
    {this.state.subscribe?<div className={style.appCont1} style={{width:'100%',height:'auto',position:'fixed',top:0,left:0,right:0, bottom:0,zIndex:9999}}>
    <div id={style.appCont2}>
       <MdClose className={style.closeIc} onClick={()=>this.setState({subscribe:false})}/>
      <p className={style.subP1}>Subscribe</p>
      <p className={style.subP2}>Subscribe to our News Letter to receive our updates</p>
      <p className={style.subTitle}>Name</p>
      <input className={style.subInput}  type='text' id='name' onChange={(event) => this.inputChange(event)}></input>
      <p className={style.subTitle}>Email</p>
      <input className={style.subInput}  type='text' id='email' onChange={(event) => this.inputChange(event)}></input>
      <p className={style.subTitle}>Country</p>
      <input className={style.subInput}  type='text' id='country' onChange={(event) => this.inputChange(event)}></input>
      <p className={style.subP3} onClick={()=>this.sendSubscribe()}>Subscribe</p>
      <p className={style.footP}>Subscribe up for inspiration on stylish, sustainable hotels. Tawanda is the conscious traveller's way to give back, without you (or the enviroment) having to pay extra</p>
    </div>
  </div>:null}
            </div>
        )
    }
}

export default Home
