import React, { Component } from 'react'
import style from './Deals.module.scss';
import {FaHotjar,FaPhoneAlt} from "react-icons/fa";
import {IoLogoWhatsapp} from 'react-icons/io';
import { MdEmail } from "react-icons/md";
class Products extends Component {
    state={
         showModal:false,
         details:[
            {id:1,name:'Mombasa Christmas 2021 Packages', det:'Mombasa is one of the best travel destinations in Kenya. Offering a vivacious mix of cultures, entertainment and architectures, its an excellent destination for travellers', country:'Nairobi, Kenya', price:20000, image:'https://images.pexels.com/photos/1591373/pexels-photo-1591373.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
            {id:2,name:'Maasai Mara Christmas',det:'The Masai Mara hold one of the the highest lion densities in thw world and this is where over two million wilderbeest and zebra follow annual migtation', country:'Mombasa, Kenya', price:35000, image:'https://images.pexels.com/photos/4017572/pexels-photo-4017572.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
            {id:3,name:'Naivasha Weekend Getaways',det:'Enjoy Kenya Great Rift Valley outstanding views along the way to Naivasha and experince the Rift serene on your stay at Naivasha', country:'Mombasa, Kenya', price:38000, image:'https://images.pexels.com/photos/4825701/pexels-photo-4825701.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
            {id:4,name:'Mt Kenya Castle Forest Hike',det:'Ultimate hiking experience on Mt Kenya Castle Forest, an experience like no other. Dont miss this once in a lifetime experience with full vicinity of Mt Kenya', price:63000, image:'https://images.pexels.com/photos/5064662/pexels-photo-5064662.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
            /*{id:5,name:'Swahili Beach',det:'3 days per person sharing', country:'Mombasa, Kenya', price:32000, image:'https://images.pexels.com/photos/404326/pexels-photo-404326.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
            {id:6,name:'Diani Sea Lodge',det:'3 days per person sharing', country:'Mombasa, Kenya', price:52000, image:'https://images.pexels.com/photos/1030948/pexels-photo-1030948.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},*/
        ]
    }
    showModal=()=>{
        this.setState({showModal:true})
    }
    hideModal=()=>{
        this.setState({showModal:false})
    }
    doNothing=(event)=>{
       event.preventDefault()
       event.stopPropagation()
    }
    render() {
        const detailsList=this.state.details.map(item=>{
            let price=item.price.toLocaleString()
            price='Starting from '+price
            let sharing = '*Per person sharing'
            return(
                <div className={style.imghoverzoom} key={item.id} onClick={()=>this.showModal()}>
                 <img style={{}} src={item.image}/>
                 <p className={style.det1}>{item.name}</p>
                 <p className={style.det3}>{item.det}</p>
                 <p className={style.det5}>Book</p>
                 { /*<h1>{item.name}</h1>
                <h4>{item.country}</h4>
              <p>{price}</p>
                <h5>{sharing}</h5>*/}
                </div>
            )
        })
        return (
            <div className={style.bodySection4} >
                {/*<div className={style.donorsVolCont}>
                    <div className={style.menuCont}>
                        <div className={style.menuCont2}>
                            <FaHotjar className={style.menuIcon}/>
                        <h1>Trending Packages</h1>
                        </div>
                    </div>
                    <p id={style.mostPopP}>Most popular packages and deals</p>
                </div>*/}
                <div className={style.donorItemsCont}>
                 {detailsList}
                </div>
                {this.state.showModal?<div className={style.modal} onClick={()=>this.hideModal()}>
                <div className={style.modalCont} onClick={(event)=>this.doNothing(event)}>
                <h5>Book/Enquire</h5>
                <p className={style.book}>For bookings and enquiries please reach us through the below contacts</p>
                <div className={style.contDiv}><FaPhoneAlt className='ulicon' size={14} color='#000'/><p>+254 712 342 209</p></div>
                <div className={style.contDiv}><MdEmail className='ulicon' size={16} color='#000'/><p>info@tawandatoursandtravels.com</p></div>
                <div className={style.contDiv}><IoLogoWhatsapp className='ulicon' size={16} color='#000'/><p>+254 712 342 209</p></div>
                <p className={style.close} onClick={()=>this.hideModal()}>Close</p>
                </div>
                </div>:null}
            </div>
        )
    }
}

export default Products
